import { actions } from 'astro:actions';
import { createEffect, createResource, createSignal, on } from 'solid-js';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '~/ui/components/actions/Modal';
import { Link } from '~/ui/components/navigation/Link';

export const BetaNotificationModal = () => {
  const [modalRef, setModalRef] = createSignal<HTMLDialogElement | null>(null);
  const [acknoweledged, { mutate }] = createResource(
    async () => {
      const { data, error } = await actions.getAcknowledgements();

      if (error || data === undefined) {
        return true;
      }

      return !!data?.find((a) => a.acknowledgement_key === 'beta_notification');
    },
    {
      initialValue: true,
    },
  );

  createEffect(
    on(acknoweledged, () => {
      if (acknoweledged()) {
        return;
      }
      modalRef()?.showModal();
    }),
  );

  const acknowledge = () => {
    actions.acknowledge({
      key: 'beta_notification',
    });
    mutate(true);
  };

  return (
    <Modal>
      <ModalContent ref={setModalRef}>
        <ModalHeader>
          <ModalTitle>Beta Version Notice</ModalTitle>
        </ModalHeader>
        <ModalDescription>
          <p class={'mb-4'}>
            You are currently viewing a beta version of Maastr.io!{' '}
          </p>

          <p class={'mb-4'}>
            As we continue to develop and improve the site, you may encounter
            some bugs or unusual behaviors. We appreciate your patience and
            understanding during this testing phase.
          </p>

          <p class={'mb-4'}>
            If you come across any issues or have feedback, please report them
            through our{' '}
            <Link href='/help' color={'secondary'}>
              help page
            </Link>
            . Your input is invaluable in helping us enhance your experience.
          </p>

          <p class={'mb-4'}>Thank you for being a part of our journey!</p>
        </ModalDescription>
        <ModalFooter>
          <ModalClose variant={'outline'} onClick={acknowledge}>
            Got it!
          </ModalClose>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
